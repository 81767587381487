@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: "Proxima";
    src: url("./assets/font/proxima/ProximaSoft-Regular.eot");
    src: url("./assets/font/proxima/ProximaSoft-Regular.ttf") format("truetype");
    font-display: swap;
  }
  @font-face {
    font-family: "Gotham";
    src: url("./assets/font/gotham/Gotham-Black.otf");
    src: url("./assets/font/gotham/GothamBold.ttf") format("truetype");
    font-display: swap;
  }
}

.btn-button {
  text-align: center;
  line-height: 1.4;
  font-weight: 400;
  font-size: 1rem;
  color: #fff;
  outline: none;
  padding: 12px 35px;
  margin: 0 5px;
  border-radius: 100px;
  border: 1px solid #fff;
  background: transparent;
}

.btn--primary-button {
  background-color: var(--primary-button);
  color: #242424;
  border: 1px solid var(--primary-button);
}

.btn--outline-button {
  background-color: transparent;
  color: #fff;
  padding: 8px 20px;
  border: 1px solid var(--primary-button);
  transition: all 0.3s ease-out;
}

.btn--medium-button {
  padding: 8px 20px;
  font-size: 18px;
}

.btn--large-button {
  padding: 12px 26px;
  font-size: 20px;
}

.btn--mobile-button {
  text-align: center;
  border-radius: 4px;
  width: 80%;
  text-decoration: none;
  font-size: 1.5rem;
  background-color: transparent;
  color: #fff;
  padding: 14px 20px;
  border: 1px solid #fff;
  transition: all 0.3s ease-out;
}

.btn--wide-button {
  padding: 12px 64px;
  font-size: 20px;
}

.btn--large-button:hover,
.btn--medium-button:hover,
.btn--mobile-button:hover {
  transition: all 0.3s ease-out;
  background: #fff;
  color: #242424;
}

.btn--wide-button:hover {
  color: #fff;
  background-color: #3700b3;
  transition: all 0.2s ease-out;
}

.btn-link-button {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  padding: 8px 16px;
  height: 100%;
  width: 100%;
}

.primary-button {
  background-color: #1b0c3a;
  color: #fff;
  border: 1px solid #1b0c3a;
}

.primary-button:hover {
  background-color: #070938;
  border: 1px solid #070938;
}

.second-button {
  background: #28a745;
  color: #fff;
  border: none;
}

.second-button:hover {
  background-color: #1b7230;
  border: 1px solid #28a745;
}

.thrid-button {
  background: #f65151;
  color: #fff;
  border: none;
}

.thrid-button:hover {
  background-color: #f65151;
  border: 1px solid #f65151;
}

.third-button {
  background-color: #6200ee;
  color: #fff;
  border: none;
}

.fourth-button {
  background: #3700b3;
  color: #fff;
  border: none;
}

.outline-orange-btn {
  border: 2px solid #ff6d4e;
  color: black !important;
}

.purple-btn {
  background-color: #4e46b4;
  color: #fff;
  border: none;
}

/* CSS */
.button-37 {
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.1) 0 2px 4px 0;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  font-weight: 400;
  outline: none;
  outline: 0;
  padding: 5px 25px;
  text-align: center;
  transform: translateY(0);
  transition: transform 150ms, box-shadow 150ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-37:hover {
  box-shadow: rgba(0, 0, 0, 0.15) 0 3px 9px 0;
  transform: translateY(-2px);
}

@media (min-width: 768px) {
  .button-37 {
    padding: 5px 30px;
  }
}

.trans-transform:hover {
  transform: translateY(-2px);
}

:root {
  --white-violet-color: #dcd9f7;
  --green-color: #edf7ef;
  --blue-bold-color: #5e68f5;
  --fifth-color: #f3dfb5;
  --dark-purple: #1e103d;
  --sixth-color: #ec6f78;
  --violet-as: #4e46b4;
  --font-violet: #918bd7;
  --curuba-color: #ff6d4e;
  --rose-color: #f65491;
  --salmon-color: #ff7659;
  --curuba-clear: #fff8f3;
  --blue-light-violet: #e2e4ed;
  --curuba-light: #ffc9be;
  --violet-dark: #27262d;
  --logo-pink: #fe7f6c;
  --midnight: #1b0c3a;
  --purple-dark: #120922;
  --black-transparent: #ffffff75;
  --more-white: #ffffffc7;
  --gray: #f9f9f9;
  --purple-light: #837c93;
  --purple-footer: #1b0c3acf;
  --violet-light: #a068b2;
  --violet-extra-light: #5057c41f;
}

.border-blue-dark {
  border: 2px solid #3e2465;
}

.border-curuba-ko {
  border-bottom: 2px solid #ff6d4e;
}

.salmon-bg-color {
  background-color: var(--salmon-color);
}

.bg-dark-purple {
  background-color: var(--dark-purple);
}

.text-dark-purple {
  color: var(--dark-purple);
}

.bg-violet-extra-light {
  background-color: var(--violet-extra-light);
}

.color-violet-light {
  color: var(--violet-light);
}

.white-transparent {
  background-color: var(--black-transparent);
}

.purple-dark {
  background-color: var(--purple-dark);
}

.purple-footer {
  background-color: var(--purple-footer);
}

.bg-curuba {
  background-color: var(--gray);
}

.white-xl-trans {
  background-color: var(---more-white);
}

.gray-bg {
  background-color: var(--gray);
}

.purple-he {
  background-color: #3e2465;
}

.color-mid {
  color: var(--midnight);
}

.bg-color-mid {
  background-color: var(--midnight);
}

.color-violet-l {
  color: var(--font-violet);
}

.color-logo-pink {
  color: var(--logo-pink);
}

.color-light-violet {
  color: var(--white-violet-color);
}

.violet-bg-as {
  background-color: var(--violet-as);
}

.color-v {
  color: var(--violet-as);
}

.violet-light-as {
  background-color: var(--font-violet);
}

/* CSS */
.button-1 {
  background-color: var(--violet-as);
  border-radius: 8px;
  border-style: none;
  box-sizing: border-box;
  color: #ffffff;
  cursor: pointer;
  display: inline-block;
  line-height: 20px;
  list-style: none;
  margin: 0;
  outline: none;
  padding: 10px 16px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: color 100ms;
  vertical-align: baseline;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-2 {
  background: rgb(85, 52, 130);
  background: linear-gradient(
    90deg,
    rgba(85, 52, 130, 1) 26%,
    rgba(120, 55, 93, 1) 71%,
    rgba(235, 135, 119, 1) 100%
  );
  border-radius: 10px;
  border-style: none;
  box-sizing: border-box;
  color: #ffffff;
  cursor: pointer;
  display: inline-block;
  line-height: 20px;
  list-style: none;
  margin: 0;
  outline: none;
  padding: 10px 16px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: color 100ms;
  vertical-align: baseline;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-2:hover {
  box-shadow: 0 0 12px #eee;
}

.blue-bold-as {
  color: var(--blue-bold-color);
}

.blue-bg-as {
  background-color: var(--blue-light-violet);
  border: 2px solid var(--violet-as);
}

.border-blue {
  border: 1px solid var(--blue-bold-color);
}

.curuba-bold-as {
  color: var(--sixth-color);
}

.curuba-bg-as {
  background-color: #de6f8d8c;
}

.border-curuba {
  border: 1px solid var(--sixth-color);
}

.curuba-color {
  color: var(--curuba-color);
}

.curuba-border {
  border-bottom: 2px solid var(--curuba-color);
}

.curuba-bg {
  background-color: var(--curuba-color);
}

.curuba-light-bg {
  background-color: var(--curuba-light);
}

.ruj {
  border-bottom: 2px solid #e3e3e3;
}

.ruj3 {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}

.ruj-2 {
  border: 2px solid #e0e2e7;
}

.ruj-3 {
  border: 2px solid #e0e2e7;
  border-radius: 12px;
}

.ruj-4 {
  border-bottom: 4px solid #1b0c3a;
  border-right: 4px solid #1b0c3a;
  border-left: 4px solid #1b0c3a;
  border-radius: 0 0 12px 12px;
}

.bor-9-round {
  border-radius: 9px 9px 0 0;
}

.green-color {
  color: var(--green-bold-color);
}

.align-items-as {
  align-items: center;
}

.violet-dark-bg {
  background-color: var(--violet-dark);
}

.gradient-as {
  background-color: #2a097c;
  background-image: linear-gradient(
    225deg,
    #2a097c 0%,
    #553482 46%,
    #eb8777 100%
  );
}

.img-auto-as {
  width: auto;
  height: auto;
  position: relative;
}

.custom-shape-divider-bottom-1665461375 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}

.custom-shape-divider-bottom-1665461375 svg {
  position: relative;
  display: block;
  width: calc(118% + 1.3px);
  height: 188px;
}

.custom-shape-divider-bottom-1665461375 .shape-fill {
  fill: #ffffff;
}

.screen-max-as {
  max-width: 1280px;
}

.font-size-giant {
  font-size: 5.35rem;
}

.gotham-font {
  font-family: "Gotham";
}

.img-1 {
  position: absolute;
  left: 3%;
  top: auto;
  right: auto;
  bottom: 5%;
  height: auto;
  width: auto;
}

.img-2 {
  position: absolute;
  left: auto;
  top: 3%;
  right: 0%;
  bottom: auto;
  height: auto;
  width: auto;
}

.real-max-as {
  max-width: 700px;
}

.section-container {
  position: relative;
  z-index: 1;
  max-width: 1064px;
}

.h-screen-min {
  min-height: 100vh;
}

.section-container-1 {
  position: relative;
  z-index: 1;
  max-width: 1064px;
  padding: 2%;
}

.error-message {
  font-size: 12px;
  color: red;
}

.border-top-purple {
  border-top: 10px solid var(--midnight);
}

.border-left-purple {
  border: 2px solid var(--midnight);
}

.sm-line-height {
  line-height: 1.1;
}

.res-circle {
  width: 3rem;
  border-radius: 50%;
  background: white;
  line-height: 0;
  position: relative;
  border: 2px solid #5057c4;
}

.res-circle-orange {
  width: 10rem;
  border-radius: 50%;
  background: #5057c41f;
  line-height: 0;
  position: relative;
  border: 4px solid #6e7fde;
}

.png-circle {
  width: 32px;
  height: 20px;
  border-radius: 50%;
}

.res-circle::after {
  content: "";
  display: block;
  padding-bottom: 100%;
}

.res-circle-orange::after {
  content: "";
  display: block;
  padding-bottom: 100%;
}

.circle-txt {
  position: absolute;
  bottom: 50%;
  font-size: 1.2rem;
  width: 100%;
  text-align: center;
  color: #5057c4;
}

.circle-txt-orange {
  position: absolute;
  bottom: 15%;
  left: 25px;
  width: 100%;
  text-align: center;
}

.height-sections {
  height: calc(100vh - 5rem);
}

.roman-ol {
  list-style-type: upper-roman !important;
}

.abcd-ul {
  list-style-type: upper-latin !important;
}

.auto-li {
  list-style: inherit;
}

.help-1 {
  background-color: #f0f4fe !important;
}

.help-2 {
  background-color: #f3f3f3 !important;
}

.border-gray-light {
  border-bottom: 2px solid #f1f1f1;
}

.isUser:hover {
  color: white;
}

.isUser.active {
  color: black;
}

@media (min-width: 640px) {
  .isUserSm.active {
    background-color: #5057c4;
    border-radius: 5px;
  }

  .isUser.active {
    color: white;
  }

  .isAdminSm.active {
    background-color: #6a70d0d4;
    border-radius: 5px;
  }
}

.bor-opt {
  box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px,
    rgba(17, 17, 26, 0.05) 0px 8px 32px;
}

.react-tel-input .form-control {
  width: 100% !important;
}

.img-chip {
  width: 24px;
  height: 24px;
  position: relative;
}

.dot {
  height: 10px;
  width: 10px;
  background-color: var(--curuba-color);
  border-radius: 50%;
  display: inline-block;
}

.select-padding {
  padding: 0.68rem;
}

.input-phone-height {
  height: 2.59rem !important;
}

.input-phone-height1 {
  height: 2.8rem !important;
}

#valueAdvance > input:focus {
  outline: none;
  caret-color: red;
}

.light-shadow-4 {
  box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
}

.light-shadow {
  box-shadow: 0px 2px 10px 1px rgba(9, 30, 66, 0.13),
    0px 0px 0px 1px rgba(9, 30, 66, 0.08);
}

.light-shadow-3 {
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
}

.light-shadow-1 {
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
    rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
}

.light-shadow-5 {
  box-shadow: rgb(14 30 37 / 12%) 0px 2px 4px 0px,
    rgb(14 30 37 / 32%) 0px 2px 16px 0px;
}

textarea {
  resize: none;
}

.h-60-as {
  height: 60vh;
}

.font-text-svg {
  font-size: 2.5rem;
}

.pb-08 {
  padding-bottom: 0.8rem;
}

.border-datePicker {
  border-radius: inherit;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.23);
}

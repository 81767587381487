.header {
  height: 55px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logo {
  display: flex;
  align-items: center;
  width: 300px;
  padding-left: 40px;
}

.logo span {
  color: #280e5f;
}

img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (min-width: 640px) {
  .item-selected:hover {
    background-color: #6a70d0d4;
    border-radius: 5px;
  }
}

.red-override {
  color: red !important;
}

.green-override {
  color: green !important;
}

.main {
  position: relative;
  width: 100%;
  min-height: calc(100vh - 60px);
}

li {
  list-style: none;
}

a {
  text-decoration: none;
}

.icon {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  font-size: 1.3rem;
}

.title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  margin-top: 30px;
}

/* responsive starts here */
@media (min-width: 768px) {
  .item-selected.active {
    background-color: #5057c4;
    border-radius: 5px;
  }
}

@media screen and (max-width: 768px) {
  .logo {
    padding-left: 30px;
    width: fit-content;
  }
}

@media screen and (max-width: 450px) {
  .logo {
    padding-left: 10px;
  }
}

.bor-opt {
  box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px,
    rgba(17, 17, 26, 0.05) 0px 8px 32px;
}

.bor-rad {
  border-radius: 6px;
}

.w-8 {
  width: 80%;
}

.border-1-as {
  border-top: 4px solid #de6fa1;
}

.border-2-as {
  border-top: 4px solid #3d9970;
}

.border-3-as {
  border-top: 4px solid #003271;
}

.border-4-as {
  border-top: 4px solid #fbd38d;
}

.border-xl {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.border-de {
  box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px,
    rgba(17, 17, 26, 0.1) 0px 0px 8px;
}
